import React from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"
import typography from "../utils/typography"
import ReadNext from "../components/ReadNext"
import Layout from "../layouts/layout.js"
import { DiscussionEmbed } from "disqus-react"

const { rhythm, scale } = typography

class BlogPostRoute extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const disqusShortname = "margot-land"
    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title,
    }
    let tags
    let tagsSection
    if (this.props.data.markdownRemark.fields.tagSlugs) {
      const tagsArray = this.props.data.markdownRemark.fields.tagSlugs
      tags = tagsArray.map((tag, i) => {
        const divider = i < tagsArray.length - 1 && <span>{" | "}</span>
        return (
          <span key={tag}>
            <Link to={tag}>
              {this.props.data.markdownRemark.frontmatter.tags[i]}
            </Link>
            {divider}
          </span>
        )
      })
      tagsSection = (
        <em
          style={{
            ...scale(-1 / 5),
            display: "block",
            marginBottom: rhythm(1),
          }}
        >
          Tagged with {tags}
        </em>
      )
    }

    return (
      <Layout location={this.props.location}>
        <div className="container">
          <div className="post">
            <Helmet
              title={`${post.frontmatter.title}`}
              meta={[{ name: "description", content: post.excerpt }]}
            />
            <h2>{post.frontmatter.title}</h2>
            <div
              className="post-body"
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            {tagsSection}
            <p
              style={{
                ...scale(-1 / 5),
                display: "block",
                marginBottom: rhythm(1),
              }}
            >
              Posted {post.frontmatter.date}
            </p>
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <ReadNext nextPost={post.frontmatter.readNext} />
            <DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
            <p
              style={{
                marginBottom: 0,
              }}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostRoute

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        author
        homecity
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      fields {
        tagSlugs
      }
      frontmatter {
        title
        tags
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
